import { v4 as uuidv4 } from "uuid";

const songData = [
    {
        title: "Unholy",
        artist: "Sam Smith",
        coverUrl:
            "https://api.audiohut.ackr8.com/HipHop/1.png",
        thumbUrl:
            "https://api.audiohut.ackr8.com/HipHop/1.png",
        audio: "https://api.audiohut.ackr8.com/HipHop/1.mp3",
        palette: "coral",
        id: uuidv4(),
    },
    {
        title: "Barbie World",
        artist: "Nicki Minaj",
        coverUrl:
            "https://api.audiohut.ackr8.com/HipHop/2.jpg",
        thumbUrl:
            "https://api.audiohut.ackr8.com/HipHop/2.jpg",
        audio: "https://api.audiohut.ackr8.com/HipHop/2.mp3",
        palette: "yellow",
        id: uuidv4(),
    },
    {
        title: "It Was A Good Day",
        artist: "Ice Cube",
        coverUrl:
            "https://api.audiohut.ackr8.com/HipHop/3.jpg",
        thumbUrl:
            "https://api.audiohut.ackr8.com/HipHop/3.jpg",
        audio: "https://api.audiohut.ackr8.com/HipHop/3.mp3",
        palette: "purple",
        id: uuidv4(),
    },
    {
        title: "Slow Down",
        artist: "Brand Nubian",
        coverUrl:
            "https://api.audiohut.ackr8.com/HipHop/4.jpeg",
        thumbUrl:
            "https://api.audiohut.ackr8.com/HipHop/4.jpeg",
        audio: "https://api.audiohut.ackr8.com/HipHop/4.mp3",
        palette: "green",
        id: uuidv4(),
    },

    {
        title: "Just Like Heaven",
        artist: "The Cure",
        coverUrl:
            "https://api.audiohut.ackr8.com/HipHop/5.jpeg",
        thumbUrl:
            "https://api.audiohut.ackr8.com/HipHop/5.jpeg",
        audio: "https://api.audiohut.ackr8.com/HipHop/5.mp3",
        palette: "purple",
        id: uuidv4(),
    },
];

export default songData;